import './app.css'

let isScrolled = false

interface Window {
  dataLayer: any[]
}

const trackEvent = (data: { event_category: string; event_action: string }) => {
  gtag('event', 'interaction', {
    event_category: data.event_category,
    event_action: data.event_action,
  })
}

document.addEventListener('DOMContentLoaded', () => {
  const nav = document.querySelector<HTMLDivElement>('nav')
  const navMenuShow = document.querySelector<HTMLDivElement>('#nav-menu-show')
  const header = document.querySelector<HTMLDivElement>('header')

  const container = document.querySelector<HTMLDivElement>('.scroll-snap-container')

  function toggleNavMenu() {
    if (!nav.classList.contains('open')) {
      nav.classList.add('open', 'expanded')
      document.body.style.overflow = 'hidden'
      if (!container) {
        return
      }
      container.style.overflow = 'hidden'
      container.style.filter = 'blur(2px)'
      trackEvent({
        event_category: 'navigation',
        event_action: 'open menu',
      })
    } else {
      nav.classList.remove('open', 'expanded')
      trackEvent({
        event_category: 'navigation',
        event_action: 'close menu',
      })
      document.body.style.overflow = ''
      if (!container) {
        return
      }
      container.style.overflow = ''
      container.style.filter = ''
    }
  }

  navMenuShow.addEventListener('click', toggleNavMenu)

  const image = document.querySelector<HTMLDivElement>('.scroll-snap-post')

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!isScrolled) {
            return
          }
          header.classList.add('lg:hidden')
          nav.classList.remove('lg:top-14', 'lg:max-h-0')
          nav.classList.add('lg:max-h-none')
        } else {
          isScrolled = true
          header.classList.remove('lg:hidden')
          nav.classList.add('lg:top-14', 'lg:max-h-0')
          nav.classList.remove('lg:max-h-none')
        }
      })
    },
    {
      root: container,
      rootMargin: '0px',
      threshold: 0.1,
    },
  )

  function debounce(func: () => void, wait: number) {
    let timeout
    return function (...args) {
      const context = this
      clearTimeout(timeout)
      timeout = setTimeout(() => func.apply(context, args), wait)
    }
  }

  const handleResize = () => {
    nav.classList.remove('open', 'expanded')
    document.body.style.overflow = ''
    if (!container) {
      return
    }
    container.style.overflow = ''
    container.style.filter = ''
  }

  const debouncedHandleResize = debounce(handleResize, 200)

  window.addEventListener('resize', debouncedHandleResize)

  if (image) {
    observer.observe(image)
  }

  const images = document.querySelectorAll<HTMLDivElement>('.scroll-snap-post')

  const logObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          trackEvent({
            event_category: 'homepage',
            event_action: 'scrolled to ' + entry.target.getAttribute('tabindex'),
          })
        }
      })
    },
    { threshold: 0.5 },
  )

  images.forEach((image) => logObserver.observe(image))
})
